import { trace } from '@opentelemetry/api';
import { useCookies } from '@magalu/mixer-utils';
import { getToken } from '@magalu/mixer-graphql';
import { useData, useStructure } from '../../hooks';
import logger from '../../utils/logger';
import setCors from '../../utils/setCors';
import foundCheck from '../../utils/foundCheck';
import foundError from '../../utils/foundError';
import setErrorStatus from '../../utils/setErrorStatus';
import isNotFound from '../../utils/isNotFound';
import removeFiltersAndRedirect from '../../utils/removeFiltersAndRedirect';
import redirectCanonicalUrl from '../../utils/redirectCanonicalUrl';
import redirectFromRules from '../../utils/redirectFromRules';
import redirectToParent from '../../utils/redirectToParent';
import redirectFromQuery from '../../utils/redirectFromQuery';
import redirectFromEmpty from '../../utils/redirectFromEmpty';

const handleRedirects = (ctx, structure, data) => {
  const inventory = [
    redirectFromRules(logger),
    redirectFromQuery(logger),
    redirectCanonicalUrl(logger),
    redirectFromEmpty(logger),
  ];

  return inventory.reduce((acc, fn) => {
    const result = fn({ ctx, data, structure });
    if (result && !Object.keys(acc).length) {
      return { ...acc, ...result };
    }

    return acc;
  }, {});
};

async function getServerSideProps(ctx) {
  const span = trace.getActiveSpan();
  try {
    const structure = await useStructure(ctx);
    if (!structure || !structure.site || !structure.theme || !structure.components) {
      // return default next 404 if not custom error page
      span?.addEvent('mixer.not.found.page.structure', {
        'log.message': 'Page cannot be loaded due structure configs not provided',
        'log.severity': 'error',
      });
      return setErrorStatus(ctx, 404, 'This page could not be found');
    }

    // sets 404 status if page not found (not in case of direct access to 404 page)
    if (isNotFound(structure) && ctx?.res) {
      ctx.res.statusCode = 404;
    }

    setCors(ctx);

    const cookies = useCookies(ctx, { logger });
    const { token } = await getToken(structure.site);
    const { data, errors } = await useData({
      structure: { ...structure, cookies },
      token,
    });

    foundError(structure, errors);

    const redirect = await handleRedirects(ctx, structure, data);
    if (Object.keys(redirect).length) return redirect;

    const found = foundCheck(structure, data);
    if (!found) {
      if (structure.notfoundFallbackUrl) {
        return redirectToParent(ctx, structure);
      }
      const hasFilter = structure?.route?.filters?.filter(
        filter => filter.origin !== 'url'
      )?.length;
      if (hasFilter) {
        span?.addEvent('mixer.filters.not.found', {
          'log.message': `${ctx.query.filters}`,
          'log.severity': 'error',
        });
        return removeFiltersAndRedirect(ctx, structure);
      }
      // try get custom 404 error page
      return getServerSideProps({
        ...ctx,
        query: {
          ...ctx.query,
        },
        routeId: '404',
      });
    }

    const props = { data, structure };
    if (structure.ssr) {
      props.structure.cookies = cookies;
      if (token) props.token = token;
    }

    return { props };
  } catch (e) {
    span?.addEvent('mixer.render.error.status.500', {
      'log.message': `Error rendering page on server side: ${e.message}`,
      'log.severity': 'error',
    });
    // return default next 500 (CDN will show custom page if exists)
    logger.error(`[Core] Error rendering a page in url: ${ctx?.resolvedUrl}`);
    return setErrorStatus(ctx, 500, e.message);
  }
}

export default getServerSideProps;
