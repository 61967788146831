import React from 'react';
import ErrorModule from 'next/error';
import { shape, number, string } from 'prop-types';
import { PublisherProvider } from '@magalu/mixer-publisher';
import { whereAmI } from '@magalu/mixer-utils';
import { parsePlaceholders } from '@magalu/mixer-structure';

import { IconProvider } from '@magalu/stereo-ui-icons';
import { ClientProvider, WishlistProvider } from '@magalu/mixer-graphql';

import Redirect from '../../components/Redirect';
import MainContainer from '../../components/MainContainer';
import EventSubscriber from '../../components/EventSubscriber';
import withPrivateRoute from '../../hocs/withPrivateRoute';

const Page = ({
  structure,
  config,
  cookies,
  data,
  errorCode,
  errorTitle,
  redirect,
  token,
  modules,
} = {}) => {
  if (errorCode) return <ErrorModule statusCode={errorCode} title={errorTitle} />;

  const parsedStructure = {
    ...structure,
    config: parsePlaceholders(structure?.config, { config: config.publicRuntimeConfig }),
  };

  return whereAmI.onClient && redirect ? (
    <Redirect url={redirect} spa={false} />
  ) : (
    <ClientProvider config={config} cookies={cookies} token={token}>
      <PublisherProvider
        settings={{
          config: config.publicRuntimeConfig,
          data,
          structure: parsedStructure,
        }}
      >
        <EventSubscriber types={structure?.config?.events || []} />
        <IconProvider>
          <WishlistProvider values={{ userId: structure?.cookies?.accountData?.id }}>
            <MainContainer
              structure={parsedStructure}
              data={data}
              config={config}
              modules={modules}
            />
          </WishlistProvider>
        </IconProvider>
      </PublisherProvider>
    </ClientProvider>
  );
};

Page.defaultProps = {
  config: {},
  cookies: {},
  data: {},
  errorCode: 0,
  errorTitle: '',
  modules: {},
  redirect: '',
  structure: {},
  token: '',
};

Page.propTypes = {
  config: shape({}),
  cookies: shape({}),
  data: shape({}),
  errorCode: number,
  errorTitle: string,
  modules: shape({}),
  redirect: string,
  structure: shape({
    theme: shape({}),
  }),
  token: string,
};

export default withPrivateRoute(Page);
