import _toConsumableArray from "@babel/runtime/helpers/esm/toConsumableArray";
import _extends from "@babel/runtime/helpers/esm/extends";
import _slicedToArray from "@babel/runtime/helpers/esm/slicedToArray";
import _classCallCheck from "@babel/runtime/helpers/esm/classCallCheck";
import _createClass from "@babel/runtime/helpers/esm/createClass";
import _assertThisInitialized from "@babel/runtime/helpers/esm/assertThisInitialized";
import _inherits from "@babel/runtime/helpers/esm/inherits";
import _possibleConstructorReturn from "@babel/runtime/helpers/esm/possibleConstructorReturn";
import _getPrototypeOf from "@babel/runtime/helpers/esm/getPrototypeOf";
function _createSuper(Derived) { var hasNativeReflectConstruct = _isNativeReflectConstruct(); return function _createSuperInternal() { var Super = _getPrototypeOf(Derived), result; if (hasNativeReflectConstruct) { var NewTarget = _getPrototypeOf(this).constructor; result = Reflect.construct(Super, arguments, NewTarget); } else { result = Super.apply(this, arguments); } return _possibleConstructorReturn(this, result); }; }
function _isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); return true; } catch (e) { return false; } }
import { EventEmitter } from 'events';
import { Singleton, whereAmI } from '@magalu/mixer-utils';
export var Publisher = function (_EventEmitter) {
  _inherits(Publisher, _EventEmitter);
  var _super = _createSuper(Publisher);
  function Publisher() {
    var _this;
    var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref$events = _ref.events,
      events = _ref$events === void 0 ? {} : _ref$events,
      _ref$settings = _ref.settings,
      settings = _ref$settings === void 0 ? {} : _ref$settings,
      _ref$delay = _ref.delay,
      delay = _ref$delay === void 0 ? 100 : _ref$delay;
    _classCallCheck(this, Publisher);
    _this = _super.call(this);
    _this.publish = _this.publish.bind(_assertThisInitialized(_this));
    _this.subscribe = _this.subscribe.bind(_assertThisInitialized(_this));
    _this.unsubscribe = _this.unsubscribe.bind(_assertThisInitialized(_this));
    _this.subscribeAll = _this.subscribeAll.bind(_assertThisInitialized(_this));
    _this.publishAllQueue = _this.publishAllQueue.bind(_assertThisInitialized(_this));
    _this.publishQueue = _this.publishQueue.bind(_assertThisInitialized(_this));
    _this.settings = settings;
    _this.eventQueue = [];
    _this.emitterReady = false;
    _this.subscribeAll(events);
    setTimeout(function () {
      _this.publishAllQueue();
    }, delay);
    return _this;
  }

  _createClass(Publisher, [{
    key: "publish",
    value:
    function publish(event) {
      var eventQueue = this.eventQueue.filter(function (_ref2) {
        var _ref3 = _slicedToArray(_ref2, 1),
          e = _ref3[0];
        return e !== event;
      });
      if (whereAmI.onClient) {
        for (var _len = arguments.length, args = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
          args[_key - 1] = arguments[_key];
        }
        if (this.emitterReady) {
          var eventId = window.crypto.randomUUID();
          this.emit.apply(this, [event].concat(args, [_extends({}, this.settings, {
            eventId: eventId
          })]));
          if (this.eventQueue.length !== eventQueue.length) {
            this.eventQueue = eventQueue;
          }
        } else {
          this.eventQueue.push([event, args]);
        }
      }
    }

  }, {
    key: "subscribe",
    value:
    function subscribe(event, callback) {
      if (whereAmI.onClient) {
        this.removeListener(event, callback);
        this.addListener(event, callback);
      }
    }

  }, {
    key: "unsubscribe",
    value:
    function unsubscribe(event, callback) {
      if (whereAmI.onClient) {
        this.removeListener(event, callback);
      }
    }

  }, {
    key: "subscribeAll",
    value:
    function subscribeAll(events) {
      var _this2 = this;
      Object.entries(events).forEach(function (_ref4) {
        var _ref5 = _slicedToArray(_ref4, 2),
          name = _ref5[0],
          callback = _ref5[1];
        if (Array.isArray(callback)) {
          callback.forEach(function (cb) {
            _this2.subscribe(name, cb);
          });
        } else {
          _this2.subscribe(name, callback);
        }
      });
    }
  }, {
    key: "publishAllQueue",
    value: function publishAllQueue() {
      var _this3 = this;
      this.emitterReady = true;
      this.eventQueue.forEach(function (_ref6) {
        var _ref7 = _slicedToArray(_ref6, 2),
          event = _ref7[0],
          args = _ref7[1];
        return _this3.publish.apply(_this3, [event].concat(_toConsumableArray(args)));
      });
    }
  }, {
    key: "setSettings",
    value: function setSettings(settings) {
      this.settings = settings;
    }

  }, {
    key: "publishQueue",
    value:
    function publishQueue(event) {
      var eventQueue = this.eventQueue.find(function (_ref8) {
        var _ref9 = _slicedToArray(_ref8, 1),
          e = _ref9[0];
        return e === event;
      });
      if (eventQueue) {
        var _eventQueue = _slicedToArray(eventQueue, 2),
          evt = _eventQueue[0],
          args = _eventQueue[1];
        this.publish.apply(this, [evt].concat(_toConsumableArray(args)));
      }
    }
  }]);
  return Publisher;
}(EventEmitter);
export default Singleton(Publisher);